import * as React from "react";
import { LayoutBox } from "../components/atoms/LayoutBox";
import Layout from "../components/Layout";
import { TextBanner } from "../components/molecules/TextBanner";

const NotFoundPage = () => (
  <Layout>
    <TextBanner title="Page not found" />
    <LayoutBox>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <p>Please select a page from the top or bottom of the page.</p>
    </LayoutBox>
  </Layout>
);

export default NotFoundPage;
